import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BuscarCarpetasService} from "./buscar-carpetas.service";
import {Carpeta} from "../../interfaces/carpeta";
import {BuscarConversacionesPorCarpetaService} from "../conversaciones/buscar-conversaciones-por-carpeta.service";
import {Conversacion, ConversacionListado} from "../../interfaces/conversacion";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {BuscarConversacionesService} from "../conversaciones/buscar-conversaciones.service";
import {EchoService} from "../../../../infrastructure/pusher/echo.service";
import {MsalService} from "@azure/msal-angular";
import Echo from "laravel-echo";
import {AccountInfo} from "@azure/msal-common";
import {ToastrService} from "ngx-toastr";
import {ObtenerConversacionService} from "../conversaciones/obtener-conversacion.service";
import {BorrarConversacionService} from "../conversaciones/borrar-conversacion.service";
import {ForzarRedireccionService} from "../../../../infrastructure/services/forzar-redireccion.service";
import {ModalEditarConversacionComponent} from "../modal-editar-conversacion/modal-editar-conversacion.component";
import {ModalConfirmacionAccionComponent} from "../modal-confirmacion-accion/modal-confirmacion-accion.component";
import {ModalEditarCarpetaComponent} from "../modal-editar-carpeta/modal-editar-carpeta.component";
import {ObtenerCarpetaService} from "./obtener-carpeta.service";
import {BorrarCarpetasService} from "./borrar-carpetas.service";
import {ModalService} from '../modal/modal.service';
import {MoverConversacionService} from "./mover-conversacion.service";
import {ListarModelosLlmActivosService} from "../../services/listar-modelos-llm-activos.service";

export interface BuscarCarpetasFG {
  descripcion: string | null;
}

@Component({
  selector: 'app-carpetas',
  templateUrl: './carpetas.component.html',
  styleUrls: ['./carpetas.component.css']
})

export class CarpetasComponent implements OnInit, OnDestroy {

  public _descripcion = {
    descripcion: null
  };
  public _asunto = {
    asunto: null
  };
  private rutaActual = '';
  public carpetas: Carpeta[] = [];
  public conversacionesMes = [] as ConversacionListado[];
  private echo: Echo<any> | undefined;
  public usuarioLogueado!: AccountInfo | null;
  public carpetaIdRuta: string = '';
  protected mostrarPrevisualizacion: boolean = false;
  @Output() public chatSeleccionado = new EventEmitter<boolean>();
  @Output() public accionRealizada = new EventEmitter<boolean>();
  private _esDispositivoMovil!: boolean;
  public tituloConversacionAMover: string = '';
  protected esConversacionBloqueGeneral: boolean = false;
  private dragCounter: number = 0;

  @Input() set esDispositivoMovil(esDispositivoMovil: boolean) {
    this._esDispositivoMovil = esDispositivoMovil;
  }

  @Input()
  set descripcion(asunto: any) {
    if (asunto) {
      this._descripcion.descripcion = asunto;
      this._asunto.asunto = asunto;
      this.buscarCarpetas();
    }
  }

  constructor(private buscarCarpetasService: BuscarCarpetasService,
              private router: Router,
              private route: ActivatedRoute,
              private title: Title,
              private buscarConversacionesPorCarpetaService: BuscarConversacionesPorCarpetaService,
              private buscarConversacionesService: BuscarConversacionesService,
              private pusherjsService: EchoService,
              private msal: MsalService,
              private toastr: ToastrService,
              private obtenerConversacionService: ObtenerConversacionService,
              private obtenerCarpetaService: ObtenerCarpetaService,
              private borrarConversacionService: BorrarConversacionService,
              private borrarCarpetaService: BorrarCarpetasService,
              private forzarRedireccionService: ForzarRedireccionService,
              private modalService: ModalService,
              private moverConversacionService: MoverConversacionService,
              private listarModelosLlmActivosService: ListarModelosLlmActivosService) {
    this.router.events.subscribe({
      next: (val) => {
        this.rutaActual = this.router.url;
      }
    })
    this.route.queryParams.subscribe(params => {
      this.carpetaIdRuta = params['carpetaId']
    })
  }

  async ngOnInit() {
    if (!this._esDispositivoMovil) {
      this.buscarCarpetas();
      this.buscarConversaciones();
    }
    this.usuarioLogueado = this.msal.instance.getActiveAccount();
    this.echo = this.pusherjsService.execute();
    this.echo?.channel('private-conversaciones.' + this.usuarioLogueado?.localAccountId).listenToAll((data: any) => {
      if (data !== 'pusher:subscription_succeeded') {
        this.buscarConversaciones();
      }
    })
  }

  public ngOnDestroy(): void {
    this.echo?.leave('private-conversaciones.' + this.usuarioLogueado?.localAccountId)
  }

  public buscarCarpetas(): void {
    this.buscarCarpetasService.__invoke('').subscribe({
      next: (carpetas: Carpeta[]) => {
        this.carpetas = carpetas
        this.carpetas.forEach((c) => {
          if (c.id == this.carpetaIdRuta) {
            c.abierta = true
            this.buscarConversacionesPorCarpeta(c)
          }
        })
      }
    });
  }

  public toggleCarpeta(carpeta: Carpeta): void {
    if (!carpeta.filtrada) {
      carpeta.abierta = !carpeta.abierta;
      this.buscarConversacionesPorCarpeta(carpeta);
    }
  }

  private buscarConversacionesPorCarpeta(carpeta: Carpeta): void {
    this.buscarConversacionesPorCarpetaService.__invoke(carpeta.id, this._asunto).subscribe({
      next: (conversaciones: ConversacionListado[]) => {
        carpeta.conversaciones = conversaciones
        if (this._descripcion.descripcion && carpeta.conversaciones.length > 0) {
          carpeta.abierta = true
          carpeta.filtrada = true
        }
      }
    })
  }

  public buscarConversaciones(): void {
    this.buscarConversacionesService.__invoke(this._asunto).subscribe({
      next: (conversaciones) => {
        conversaciones.forEach((conversaciones: ConversacionListado) => {
          conversaciones.conversaciones.forEach((conversacion: Conversacion) => {
            conversacion.esConversacionBloqueGeneral = true;
          })
        });
        this.conversacionesMes = conversaciones
      }
    });
  }

  async navegarChat(conversacion: Conversacion, $event: MouseEvent) {
    this.chatSeleccionado.emit(true);
    await this.router.navigate(['/chat/' + conversacion.id], {queryParams: {carpetaId: conversacion.carpetaId}});
    this.title.setTitle(conversacion.asunto);
  }

  public editarConversacion(conversacion: Conversacion, $event: MouseEvent): void {
    $event.stopPropagation();
    this.accionRealizada.emit(true);
    this.listarModelosLlmActivosService.__invoke().subscribe({
      next: (modelosActivos) => {
        this.obtenerConversacionService.__invoke(conversacion.id).subscribe({
          next: (conversacion) => {
            const modalRef = this.modalService.loadComponent(ModalEditarConversacionComponent);
            modalRef.instance.setConversacion(conversacion);
            modalRef.instance.modelosActivos = modelosActivos;
            this.modalService.setTitulo('Editar conversación');
            this.modalService.onDidDismiss$.subscribe({
              next: async (result) => {
                if (result) {
                  this.buscarConversaciones();
                  this.buscarCarpetas();
                  if (this.compruebaChatActual(result)) {
                    await this.forzarRedireccionService.__invoke('/chat/' + conversacion.id)
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  public editarCarpeta(carpeta: Carpeta, $event: MouseEvent): void {
    $event.stopPropagation();
    this.accionRealizada.emit(true);
    this.obtenerCarpetaService.__invoke(carpeta.id).subscribe({
      next: (carpeta: Carpeta) => {
        const modalRef = this.modalService.loadComponent(ModalEditarCarpetaComponent);
        this.modalService.setTitulo('Editar carpeta');
        modalRef.instance.carpeta = carpeta
        this.modalService.onDidDismiss$.subscribe({
          next: (result) => {
            if (result) {
              this.buscarCarpetas();
            }
          }
        });
      }
    });
  }

  public confirmarBorrado(conversacion: Conversacion, $event: MouseEvent, carpeta: Carpeta | null): void {
    $event.stopPropagation();
    this.accionRealizada.emit(true);
    const modalRef = this.modalService.loadComponent(ModalConfirmacionAccionComponent);
    this.modalService.setTitulo('Borrar conversación');
    modalRef.instance.actionConfirmationData = {
      mensaje: '¿Está seguro que desea borrar la conversación?',
    }
    this.modalService.onDidDismiss$.subscribe({
      next: (result) => {
        if (result) {
          this.borrarConversacion(conversacion.id, carpeta);
        }
      }
    });
  }

  private borrarConversacion(id: string, carpeta: any): void {
    this.borrarConversacionService.__invoke(id).subscribe({
      next: () => {
        this.toastr.success('Conversación borrada correctamente');
        this.router.navigate(['/inicio'])
        if (carpeta === null) {
          this.buscarConversaciones();
        } else {
          this.buscarConversacionesPorCarpeta(carpeta);
        }
      }, error: (error) => {
        this.toastr.error('Error al borrar la conversación');
      }
    });
  }

  private compruebaChatActual(result: Conversacion) {
    return this.router.url.includes(result.id);
  }

  public confirmarBorradoCarpeta(carpeta: Carpeta, $event: MouseEvent): void {
    $event.stopPropagation();
    this.accionRealizada.emit(true);
    const modalRef = this.modalService.loadComponent(ModalConfirmacionAccionComponent);
    this.modalService.setTitulo('Borrar carpeta');
    modalRef.instance.actionConfirmationData = {
      mensaje: '¿Está seguro que desea borrar la carpeta? <p>Sus conversaciones se moverán con el resto de conversaciones</p>',
    }
    this.modalService.onDidDismiss$.subscribe({
      next: (result) => {
        if (result) {
          this.borrarCarpeta(carpeta.id);
        }
      }
    });
  }

  private borrarCarpeta(id: string): void {
    this.borrarCarpetaService.__invoke(id).subscribe({
      next: () => {
        this.toastr.success('Carpeta borrada correctamente');
        this.buscarCarpetas();
        this.buscarConversaciones();
      }, error: (error) => {
        this.toastr.error('Error al borrar la carpeta');
      }
    });
  }

  public conversacionActiva(id: string, carpetaId: string | null): boolean {
    if (carpetaId !== null)
      return this.rutaActual === '/chat/' + id + '?carpetaId=' + carpetaId;
    else
      return this.rutaActual === '/chat/' + id + '?carpetaId='
  }

  public onDragStart(event: DragEvent, conversacion: Conversacion): void {
    conversacion.dragOn = true;
    this.esConversacionBloqueGeneral = conversacion.esConversacionBloqueGeneral ?? false;
    event.dataTransfer?.setData('text/plain', conversacion.id);
    this.tituloConversacionAMover = conversacion.asunto;
  }

  public onDragOver(event: DragEvent, carpeta?: Carpeta): void {
    event.preventDefault();
    if (carpeta) {
      carpeta.dragOn = true;
    }
  }

  public onDragEnter(event: DragEvent, carpeta?: Carpeta, zona: number = 1): void {
    event.preventDefault();
    if (zona == 2) {
      this.dragCounter++;
    }
    if (carpeta) {
      carpeta.dragOn = true;
    } else {
      if (this.dragCounter === 1) {
        this.mostrarPrevisualizacionConversacion(true);
      }
    }
  }

  public onDrop(event: DragEvent, carpeta?: Carpeta, conversacion?: Conversacion): void {
    event.preventDefault();
    this.dragCounter = 0;
    if (carpeta) {
      carpeta.dragOn = false;
    }
    if (conversacion) {
      conversacion.dragOn = false;
    }
    this.mostrarPrevisualizacion = false;
    this.tituloConversacionAMover = '';
    const carpetaId = carpeta ? carpeta.id : null;
    const conversacionId = event.dataTransfer?.getData('text');
    if (conversacionId) {
      this.moverConversacion(conversacionId, carpetaId);
    }
  }

  public moverConversacion(conversacionId: string, carpetaId: string | null): void {
    this.moverConversacionService.__invoke(conversacionId, carpetaId).subscribe({
      next: () => {
        this.toastr.success('Conversación movida correctamente', undefined, {
          timeOut: 1000
        });
        this.buscarConversaciones();
        this.buscarCarpetas();
      },
      error: () => {
        this.toastr.error('Error al mover la conversación');
      }
    });
  }

  public onDragLeave(event: DragEvent, carpeta?: Carpeta, zona: number = 1): void {
    event.preventDefault()
    if (zona == 2) {
      this.dragCounter--;
    }
    if (carpeta) {
      carpeta.dragOn = false;
    } else {
      if (this.dragCounter === 0) {
        this.mostrarPrevisualizacionConversacion(false);
      }
    }
  }

  private mostrarPrevisualizacionConversacion(estado: boolean): void {
    if (this.mostrarPrevisualizacion !== estado) {
      this.mostrarPrevisualizacion = estado;
    }
  }
}
