import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {CustomSelectComponent} from './custom-select.component';

@Injectable({
  providedIn: 'root'
})
export class CustomSelectService {

  private selectOpened: Subject<CustomSelectComponent> = new Subject<CustomSelectComponent>();

  public selectOpened$: Observable<CustomSelectComponent> = this.selectOpened.asObservable();

  public notifySelectOpened(select: CustomSelectComponent): void {
    this.selectOpened.next(select);
  }
}
