import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChildren
} from '@angular/core';
import {ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomSelectService} from './custom-select.service';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    }
  ]
})
export class CustomSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public _elementos!: any[];
  public _elementosOriginales = [] as any[];
  public _elementoSelect: any = undefined;
  public _propiedad: string = 'id';
  public _etiqueta: string = 'descripcion';
  public _opcionVacia: string = 'Todos';
  public _opcionVaciaSeleccionada: boolean = false;
  public _mostrarOpcionVacia: boolean = true;
  public opened = false;
  private _valorElementoInicial: any;
  private value: any;
  private _orderBy = this._etiqueta;
  protected _busqueda = false;
  public busquedaTexto: string = '';
  public selectId: string = Math.random().toString(36).substring(7);
  private subscription!: Subscription;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const clickedInside = (event.target as HTMLElement).closest('.relative.mt-2');
    if (!clickedInside) {
      this.opened = false;
      this.highlightedIndex = -1;
    }
  }

  // Nueva propiedad para rastrear el índice del elemento resaltado
  public highlightedIndex: number = -1;

  @Input() set valorElementoInicial(value: any) {
    this._valorElementoInicial = value;
  }

  @Output() public elementoSeleccionado: EventEmitter<any> = new EventEmitter<any>();
  @Input() formControlName: string = '';
  @Input() disabled: boolean = false;

  @Input() set orderBy(value: string) {
    this._orderBy = value;
    this.ordenarElementos();
  }

  @Input() set elementos(value: any[] | undefined) {
    if (value) {
      this._elementos = value;
      this._elementosOriginales = value;
      if (value.length > 0) {
        this.setElementoSelect(this._valorElementoInicial ?? this.value);
      }
      this.ordenarElementos();
    }
  }

  @Input() set elementoSelect(value: any) {
    if (!value) {
      this._elementoSelect = null;
      this._opcionVaciaSeleccionada = true;
      return;
    }
    this.setElementoSelect(value);
  }

  @Input() set propiedad(value: string) {
    this._propiedad = value;
  }

  @Input() set etiqueta(value: string) {
    this._etiqueta = value;
  }

  @Input() set opcionVacia(value: string) {
    this._opcionVacia = value;
  }

  @Input() set mostrarOpcionVacia(value: boolean) {
    this._mostrarOpcionVacia = value;
  }

  @Input() set busqueda(value: boolean) {
    this._busqueda = value;
  }

  private onChange: any = () => {
  };
  private onTouched: any = () => {
  };

  constructor(private rootFormGroup: FormGroupDirective,
              private customSelectService: CustomSelectService) {
  }

  public ngOnInit(): void {
    this.subscription = this.customSelectService.selectOpened$.subscribe((openedSelect) => {
      if (openedSelect !== this) {
        this.opened = false;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this._opcionVaciaSeleccionada = !value;
    this.setElementoSelect(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * HostListener para manejar eventos de teclado.
   */
  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.disabled) return;

    if (!this.opened) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        this.toggleElementos();
        event.preventDefault();
      }
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
        this.moveHighlight(1);
        event.preventDefault();
        break;
      case 'ArrowUp':
        this.moveHighlight(-1);
        event.preventDefault();
        break;
      case 'Enter':
        this.selectHighlighted();
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'Escape':
        this.toggleElementos();
        event.preventDefault();
        event.stopPropagation();
        break;
      default:
        break;
    }
  }

  /**
   * Mueve el índice resaltado en la dirección indicada.
   * @param direction 1 para abajo, -1 para arriba
   */
  private moveHighlight(direction: number): void {
    if (!this._elementos || this._elementos.length === 0) {
      return;
    }

    // Calcular el número total de elementos incluyendo la opción vacía si está visible
    const totalElements = this._mostrarOpcionVacia ? this._elementos.length + 1 : this._elementos.length;

    if (this.highlightedIndex === -1) {
      // Sí hay una opción seleccionada, establecer el índice en ella; de lo contrario, en la opción vacía
      if (this._elementoSelect) {
        const selectedIndex = this._elementos.findIndex(elemento => elemento[this._propiedad] === this.value);
        this.highlightedIndex = this._mostrarOpcionVacia ? selectedIndex + 1 : selectedIndex;
      } else if (this._mostrarOpcionVacia) {
        this.highlightedIndex = 0;
      } else {
        this.highlightedIndex = 0;
      }
    } else {
      this.highlightedIndex += direction;
      if (this.highlightedIndex < 0) {
        this.highlightedIndex = 0; // No permitir ir más arriba del primer elemento
      } else if (this.highlightedIndex >= totalElements) {
        this.highlightedIndex = totalElements - 1; // No permitir ir más abajo del último elemento
      }
    }

    this.selectHighlighted(false);
    this.scrollToHighlighted();
  }

  /**
   * Selecciona el elemento actualmente resaltado.
   */
  private selectHighlighted(autoClose = true): void {
    if (this.highlightedIndex === 0 && this._mostrarOpcionVacia) {
      this.seleccionarElemento(null, autoClose, false);
    } else {
      const index = this._mostrarOpcionVacia ? this.highlightedIndex - 1 : this.highlightedIndex;
      if (index >= 0 && index < this._elementos.length) {
        const elemento = this._elementos[index];
        this.seleccionarElemento(elemento, autoClose, false);
      }
    }
  }

  /**
   * Asegura que el elemento resaltado sea visible dentro del contenedor.
   */
  private scrollToHighlighted(): void {
    const listElement = document.getElementById(`list-${this.selectId}`);
    if (listElement && this.highlightedIndex >= 0) {
      const itemElement = listElement.children[this.highlightedIndex] as HTMLElement;
      if (itemElement) {
        itemElement.scrollIntoView({behavior: 'auto', block: 'center', inline: 'nearest'});
      }
    }
  }

  /**
   * Alterna la apertura/cierre del desplegable.
   */
  public toggleElementos(): void {
    this.busquedaTexto = '';
    this.buscar();
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;

    if (this.opened && this._busqueda) {
      this.establecerFoco();
    }

    if (this.opened) {
      this.customSelectService.notifySelectOpened(this);
      // Al abrir, establecer el highlightedIndex en el elemento seleccionado
      if (this._elementoSelect) {
        const selectedIndex = this._elementos.findIndex(elemento => elemento[this._propiedad] === this.value);
        this.highlightedIndex = this._mostrarOpcionVacia ? selectedIndex + 1 : selectedIndex;
      } else if (this._mostrarOpcionVacia) {
        this.highlightedIndex = 0;
      } else {
        this.highlightedIndex = 0;
      }
      // Desplazar al elemento resaltado para asegurarse de que esté visible
      setTimeout(() => {
        this.scrollToHighlighted();
      }, 0);
    } else {
      this.highlightedIndex = -1; // Reiniciar el índice al cerrar
    }
  }

  public seleccionarElemento(elemento: any, autoClose = true, limpiarBuscador: boolean): void {
    if (limpiarBuscador) {
      this.busquedaTexto = '';
      this.buscar();
    }

    if (elemento === null) {
      this._elementoSelect = null;
      this._opcionVaciaSeleccionada = true;
      this.onChange(null);
      this.value = null;
      this.highlightedIndex = 0; // Establecer en la opción vacía
      if (autoClose) {
        this.toggleElementos();
      }
      this.elementoSeleccionado.emit(elemento);
      return;
    }
    this._opcionVaciaSeleccionada = false;
    this._elementoSelect = elemento;
    this.onChange(elemento[this._propiedad]);
    this.value = elemento[this._propiedad];
    if (autoClose) {
      this.toggleElementos();
    }
    this.highlightedIndex = this._mostrarOpcionVacia
      ? this._elementos.findIndex(el => el[this._propiedad] === elemento[this._propiedad]) + 1
      : this._elementos.findIndex(el => el[this._propiedad] === elemento[this._propiedad]);
    this.elementoSeleccionado.emit(elemento);
  }

  /**
   * Establece el elemento seleccionado basado en el valor.
   * @param value El valor del elemento a seleccionar.
   */
  private setElementoSelect(value: any): void {
    if (!this._elementos) {
      return;
    }

    this._elementoSelect = this._elementos.find((elemento) => {
      return elemento[this._propiedad] === value;
    });

    this.value = this._elementoSelect ? this._elementoSelect[this._propiedad] : undefined;
    this._opcionVaciaSeleccionada = !this.value;
  }

  /**
   * Ordena los elementos según la propiedad especificada.
   */
  private ordenarElementos(): void {
    if (!this._elementos) {
      return;
    }

    if (this._elementos.length === 0) {
      return;
    }
    this._elementos.sort((a, b) => {
      const aId = a.id;
      const bId = b.id;

      if (aId === -1 && bId !== -1) return -1;
      if (bId === -1 && aId !== -1) return 1;
      if (aId === null && bId !== null) return -1;
      if (bId === null && aId !== null) return 1;

      return a[this._orderBy].localeCompare(b[this._orderBy]);
    });
  }

  /**
   * Filtra los elementos según el texto de búsqueda.
   */
  protected buscar() {
    this.elementos = [...this._elementosOriginales];
    if (this.busquedaTexto) {
      this._elementos = this._elementos.filter((elemento) => {
        return elemento[this._etiqueta].toLowerCase().includes(this.busquedaTexto.toLowerCase());
      });
    }
  }

  /**
   * Establece el foco en el input de búsqueda.
   */
  private establecerFoco(): void {
    setTimeout(() => {
      const input = document.getElementById(this.selectId);
      if (input) {
        input.focus();
      }
    }, 100);
  }
}
