import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html',
  styleUrls: ['./ayuda.component.css']
})
export class AyudaComponent implements OnInit {
  public _rutaWordpress!: string;
  public sanitizedUrl!: SafeResourceUrl;

  @Input()
  public set rutaWordpress(ruta: string | undefined) {
    if (ruta) {
      this._rutaWordpress = ruta;
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._rutaWordpress);
    }
  };

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {

  }
}
