<div class="grid grid-cols-1 mt-1" [formGroup]="crearConversacionForm">

  <div *ngIf="modelosLLMActivos && modelosLLMActivos.length == 0"
       class="flex flex-col w-full flex-grow relative shadow-xs bg-white gizmo:bg-[#F5F5F5] gizmo:shadow-[0_0_0_2px_rgba(255,255,255,0.95)] mb-3">
    <div class="rounded-md bg-yellow-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm text-yellow-700">
            {{ 'No se puede crear una conversación si no hay ningún modelo LLM configurado' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="my-2" [ngClass]="{'hidden': perfilesConversacion && perfilesConversacion.length === 1}">
    <label for="perfil_conversacion_id"
           class="block text-sm font-medium leading-6 text-gray-900">{{ 'Perfil de conversación' | translate }}</label>
    <app-custom-select
      id="perfil_conversacion_id"
      [elementos]="perfilesConversacion"
      etiqueta="descripcion"
      propiedad="id"
      opcionVacia="{{permiteOpcionPersonalizada ? 'Ninguno' : ''}}"
      [mostrarOpcionVacia]="permiteOpcionPersonalizada"
      formControlName="perfil_conversacion_id"
      [busqueda]="true"
      [disabled]="modelosLLMActivos && modelosLLMActivos.length == 0">
    </app-custom-select>
    <p class="mt-2 text-sm text-red-600" *ngIf="crearConversacionForm.get('perfil_conversacion_id')!.errors">
      {{ crearConversacionForm.get('perfil_conversacion_id')!.errors?.[0] }}
    </p>
  </div>
  <div [ngClass]="{'my-2': modelosLLMActivos && modelosLLMActivos.length > 1}">
    <ng-container *ngIf="modelosLLMActivos && modelosLLMActivos.length > 1">
      <label for="modelo"
             class="block text-sm font-medium leading-6 text-gray-900">{{ 'Modelo' | translate }}</label>
      <app-custom-select
        id="modelo"
        [elementos]="modelosLLMActivos"
        etiqueta="descripcion"
        propiedad="nombre"
        [valorElementoInicial]="crearConversacionForm.get('modelo')?.value"
        [mostrarOpcionVacia]="true"
        opcionVacia="Ninguno"
        formControlName="modelo"
        [disabled]="perfilTieneModelo"
        [busqueda]="true">
      </app-custom-select>
    </ng-container>
    <p class="mt-2 text-sm text-red-600" *ngIf="crearConversacionForm.get('modelo')!.errors">
      {{ crearConversacionForm.get('modelo')!.errors?.[0] }}
    </p>
  </div>
  <div class="my-2">
    <label for="asunto"
           class="block text-sm font-medium leading-6 text-gray-900">{{ 'Asunto' | translate }}</label>
    <div class="relative mt-2 rounded-md shadow-sm">
      <input type="text" formControlName="asunto" id="asunto"
             [ngClass]="crearConversacionForm.get('asunto')!.errors ? 'ring-1 ring-inset ring-red-300' : 'ring-1 ring-inset ring-gray-300'"
             class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50">
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
             *ngIf="crearConversacionForm.get('asunto')!.errors">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"/>
        </svg>
      </div>
    </div>
    <p class="mt-2 text-sm text-red-600" *ngIf="crearConversacionForm.get('asunto')!.errors">
      {{ crearConversacionForm.get('asunto')!.errors?.[0] }}
    </p>
  </div>
  <div class="my-2">
    <label for="carpeta_id"
           class="block text-sm font-medium leading-6 text-gray-900">{{ 'Carpeta' | translate }}</label>
    <app-custom-select
      id="carpeta_id"
      [elementos]="carpetas"
      etiqueta="descripcion"
      opcionVacia="Ninguna"
      propiedad="id"
      formControlName="carpeta_id"
      [disabled]="modelosLLMActivos && modelosLLMActivos.length == 0">
    </app-custom-select>
  </div>
</div>
<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
  <button type="button" (click)="crearChat()" appLoadingButton [loading]="loading"
          [disabled]="modelosLLMActivos && modelosLLMActivos.length === 0"
          [ngClass]="(modelosLLMActivos && modelosLLMActivos.length === 0) ? 'bg-indigo-200' : 'hover:bg-indigo-500'"
          class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto">
    {{ 'Guardar' | translate }}
  </button>
  <button type="button" (click)="dismiss()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
    {{ 'Cancelar' | translate }}
  </button>
</div>

