<div class="relative mt-2">
  @if (_elementos) {
    <button
      type="button"
      (click)="toggleElementos()"
      [disabled]="disabled"
      [ngClass]="{'opacity-50 cursor-not-allowed': disabled, 'cursor-pointer': !disabled}"
      class="relative w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
      aria-haspopup="listbox"
      [attr.aria-expanded]="opened"
      aria-labelledby="listbox-label"
      tabindex="0"
    >
      <span class="inline-flex w-full truncate">
        <span>{{ _elementoSelect ? _elementoSelect[_etiqueta] : (_opcionVacia | translate) }}</span>
      </span>
      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>
    <div
      class="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      *ngIf="opened">
      <ul
        [ngClass]="{'block': opened, 'hidden': !opened}"
        class="max-h-60 overflow-auto"
        role="listbox"
        aria-labelledby="listbox-label"
        id="list-{{selectId}}"
      >
        <!-- Campo de búsqueda -->
        @if (_busqueda) {
          <li class="text-gray-900 relative select-none py-1 px-3">
            <input
              [(ngModel)]="busquedaTexto"
              (input)="buscar()"
              placeholder="Buscar..."
              id="{{selectId}}"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </li>
        }

        <!-- Opción Vacía -->
        @if (_mostrarOpcionVacia && !busquedaTexto) {
          <li
            role="option"
            (click)="seleccionarElemento(null, true, true)"
            [class.selected]="_elementoSelect === null || _elementoSelect === undefined"
            [class.highlighted]="highlightedIndex === 0"
            class="text-gray-900 relative select-none py-2 pl-3 pr-9 cursor-pointer"
            [ngClass]="{
              'hover:bg-indigo-600 hover:text-white': true,
              'bg-indigo-50': _elementoSelect === null || _elementoSelect === undefined || highlightedIndex === 0
            }">
            <span class="font-normal block truncate">{{ _opcionVacia | translate }}</span>
            <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                  *ngIf="_elementoSelect === null || _elementoSelect === undefined"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        }

        <!-- Lista de Elementos -->
        <ng-container *ngFor="let elemento of _elementos; let i = index">
          <li
            role="option"
            (click)="seleccionarElemento(elemento, true, true)"
            [class.selected]="_elementoSelect && _elementoSelect[_propiedad] === elemento[_propiedad]"
            [class.highlighted]="highlightedIndex === (i + (_mostrarOpcionVacia ? 1 : 0))"
            class="text-gray-900 relative select-none py-2 pl-3 pr-9 cursor-pointer"
            [ngClass]="{
              'hover:bg-indigo-600 hover:text-white': true,
              'bg-indigo-50 ': _elementoSelect && _elementoSelect[_propiedad] === elemento[_propiedad] || highlightedIndex === (i + (_mostrarOpcionVacia ? 1 : 0)),
            }"
          >
            <span class="font-normal block truncate">{{ elemento[_etiqueta] | translate }}</span>
            <span
              class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
              *ngIf="_elementoSelect && _elementoSelect[_propiedad] === elemento[_propiedad]"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ng-container>
      </ul>
    </div>
  } @else {
    <button
      [disabled]="true"
      class="relative w-full rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
      aria-haspopup="listbox"
      aria-expanded="false"
      aria-labelledby="listbox-label"
    >
      <div class="flex items-center gap-3">
        <div class="relative h-6 w-6 shrink-0 overflow-hidden rounded-md text-indigo-600">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="black"
               class="h-6 w-6 flex-shrink-0 p-1 rotate-icon">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M272 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V16c0-8.8 7.2-16 16-16s16 7.2 16 16zm0 384v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V400c0-8.8 7.2-16 16-16s16 7.2 16 16zM0 256c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zm400-16h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H400c-8.8 0-16-7.2-16-16s7.2-16 16-16zM437 75c6.2 6.2 6.2 16.4 0 22.6l-67.9 67.9c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L414.4 75c6.2-6.2 16.4-6.2 22.6 0zM165.5 369.1L97.6 437c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l67.9-67.9c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6zM75 75c6.2-6.2 16.4-6.2 22.6 0l67.9 67.9c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L75 97.6c-6.2-6.2-6.2-16.4 0-22.6zM369.1 346.5L437 414.4c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-67.9-67.9c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0z"
            />
          </svg>
        </div>
        <div class="text-sm">{{ 'Cargando...' | translate }}</div>
      </div>
      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>
  }
</div>
